#statistics {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

#statistics-text-container {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  align-items: flex-end;
}

#statistics-average-icon {
  font-family: whatsapp-emoticons;
  font-size: 1.5rem;
}

#statistics-moods-container {
  margin-top: 2rem;
  display: flex;
  gap: 0.75rem;
}

.statistics-mood {
  display: flex;
  align-items: flex-start;
  gap: 0.2rem;
}

.statistics-mood-icon {
  font-family: whatsapp-emoticons;
  font-size: 2.75rem;
}

.statistics-mood-counter {
  font-family: six-hands-condensed, sans-serif;
  font-size: 1.5rem;
}

#statistics-tip-container {
  margin-top: 0.5rem;
  display: flex;
  gap: 1rem;
}

#statistics-tip-arrow {
  width: 3rem;
}

#statistics-tip-text {
  font-family: six-hands-condensed, sans-serif;
  font-size: 1.5rem;
  text-transform: uppercase;
}

@media screen and (orientation: portrait) {
  #statistics {
    width: 100%;
    align-items: center;
  }

  #statistics-text-container {
    font-size: smaller;
  }

  #statistics-mood-container {
    margin-top: 1rem;
  }

  .statistics-mood-icon {
    font-size: 2.2rem;
  }

  .statistics-mood-counter {
    font-size: 1.25rem;
  }

  #statistics-tip-container {
    align-self: flex-end;
  }

  #statistics-tip-text {
    font-size: 1.25rem;
  }
}
