.moodlist-item {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.moodlist-date-title {
  width: 100%;
  margin-top: 2rem;
  font-weight: bold;
  text-transform: uppercase;
}

.mood-icon {
  font-family: whatsapp-emoticons;
  font-size: 2rem;
}

.mood-text-container {
  width: calc(100% - 6rem);
}

.mood-text-top-row {
  font-size: 1.1rem;
}

.mood-text--bottom-row {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.mood-date-time {
  font-family: six-hands-condensed, sans-serif;
  font-size: 1.25rem;
}

.mood-options-container {
  display: none;
  gap: 0.25rem;
}

#mood-options-shown {
  display: flex;
}

.mood-options-button {
  height: 1rem;
}

.mood-options-button img {
  height: 100%;
}

.mood-options-button:hover {
  transform: scale(1.5);
}

#edit-mood-icon {
  transform: rotate(-10deg);
}

@media screen and (orientation: portrait) {
  .mood-title {
    font-size: 0.9rem;
  }

  .mood-options-container {
    display: flex;
  }

  .mood-options-button:hover {
    transform: none;
  }
}
