/* 📌 FONTS */

/* ADOBE FONTS */
@import url('https://use.typekit.net/wqa7jgj.css');

/* EMOJI ICONS */
@font-face {
  font-family: 'whatsapp-emoticons';
  src: url('assets/FONT/whatsapp_emoticons-webfont.woff2') format('woff2'),
    url('assets/FONT/whatsapp_emoticons-webfont.woff') format('woff');
}

/* 📌 RESET */

@import url('assets/CSS/_reset.css');
