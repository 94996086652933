#loading-container {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

#loading-icon {
  width: 5rem;
  animation: rotate 1.5s infinite linear;
}

#loading-icon img {
  width: 100%;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}

#loading-text {
  font-size: 1.5rem;
  animation: blink 0.5s infinite linear;
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
