#moodlist {
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
}

#moodlist-separator {
  display: none;
}

#moodlist-items-container {
  position: relative;
}

#no-moods {
  margin: 3rem 0;
  font-size: 1.25rem;
}

@media screen and (orientation: portrait) {
  #moodlist {
    width: 100%;
  }

  #moodlist-separator {
    display: flex;
    width: 100%;
    margin: 3rem 0;
    justify-content: center;
  }

  #moodlist-separator img {
    width: 40%;
  }
}
