header {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  column-gap: 5%;
}

#page-title {
  width: 60%;
  font-family: audrielle-no1, sans-serif;
  font-size: 8rem;
  text-align: end;
  transform: rotate(-5deg);
}

#page-title span {
  font-family: audrielle-no2, sans-serif;
}

nav {
  width: 40%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 1rem;
}

#search-date-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
}

#search-date-input {
  padding: 0.25rem;
  border: 0;
  background: transparent;
  font-family: six-hands-condensed, sans-serif;
  font-size: 1.25rem;
  color: #000;
}

#close-search-button {
  width: 1rem;
}

#close-search-button img {
  width: 100%;
}

#nav-icon-container {
  display: flex;
  gap: 0.5rem;
}

.nav-icon {
  height: 2.5rem;
}

.nav-icon img {
  height: 100%;
}

.nav-icon-selected {
  transform: scale(1.2);
}

@media screen and (orientation: portrait) {
  header {
    width: 100%;
    margin-bottom: 1.5rem;
    flex-direction: column;
  }

  #page-title {
    font-size: 7rem;
    align-self: flex-start;
  }

  nav {
    width: 100%;
    justify-content: flex-end;
  }

  #search-date-container {
    margin-right: 1rem;
    column-gap: 0.5rem;
    justify-content: flex-end;
  }

  .nav-icon {
    font-size: 3rem;
  }
}
