.modal-container {
  width: 30%;
  padding: 1rem 2rem;
  position: relative;
  background: linear-gradient(120deg, #d1d1d1 0%, #c3c3c3 35%, #bfbfbf 100%);
  box-shadow: 0.25rem 0.25rem #00000050;
  transform: rotate(-0.5deg);
}

.modal-close-icon {
  width: 1rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.modal-close-icon:hover {
  transform: scale(1.25);
}

.modal-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (orientation: portrait) {
  .modal-container {
    width: 90%;
  }
}
