.Overlay {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(120deg, #9975b4bb 0%, #9569b7bb 35%, #76449cbb 100%);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 1;
}
