#mood-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#alert-no-type {
  width: 100%;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  /* animation: wink 0.25s infinite linear; */
}

/* @keyframes wink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.3;
  }
} */

#no-type-icon {
  width: 1.5rem;
}

#no-type-icon img {
  width: 100%;
}

#no-type-text {
  font-size: 1.1rem;
  text-transform: uppercase;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.input {
  padding: 0.25rem;
  border: 0;
  border-bottom: #000 1px dashed;
  background: transparent;
  font-family: six-hands-condensed, sans-serif;
  font-size: 1.25rem;
  color: #000;
}

.input::placeholder {
  color: #00000060;
}

#mood-type-container {
  width: 100%;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1%;
  font-family: whatsapp-emoticons;
  font-size: 3rem;
}

.active-mood {
  font-size: 3.5rem;
}

#date-time-container {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

#text-container,
#text-input {
  width: 100%;
  font-family: six-hands-marker, sans-serif;
  font-size: 1rem;
}

#form-buttons-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 2rem;
}

#close-button {
  width: 1.5rem;
}

#close-button img {
  width: 100%;
}

#send-button {
  min-width: 4.75rem;
  border: 0;
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  background: transparent;
  font-family: six-hands-condensed, sans-serif;
  font-size: 3rem;
  text-transform: uppercase;
  color: #000;
}

#send-button-icon {
  font-family: whatsapp-emoticons;
  font-size: 1.5rem;
}
