* {
  box-sizing: border-box;
  outline: none;
  user-select: none;
}

body {
  width: 100vw;
  min-height: 100vh;
  padding: 5% 10%;
  overflow-x: hidden;
  background: #efe3f8;
  background: linear-gradient(120deg, #efe3f8 0%, #d3b5e9 35%, #9975b4 100%);
  font-family: six-hands-marker, sans-serif;
  font-weight: 400;
  color: #000000;
}

#root {
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

a {
  color: inherit;
  text-decoration: none;
}

h2 {
  font-size: 2rem;
  font-weight: normal;
}

.clickable:hover {
  transition: 0.25s all;
  transform: scale(1.2);
  cursor: pointer;
}

#outer-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

main {
  width: 100%;
  display: flex;
  padding: 5% 0;
}

.section-title {
  margin-bottom: 3%;
  align-self: flex-end;
  font-size: 2rem;
  text-align: right;
}

.section-title-underline {
  width: 30%;
  position: relative;
  bottom: 0.75rem;
  align-self: flex-end;
}

@media screen and (orientation: landscape) {
  #outer-container {
    width: 80%;
  }

  main {
    flex-direction: row;
    column-gap: 5%;
  }
}

@media screen and (orientation: portrait) {
  h2 {
    font-size: 1.5rem;
  }

  #outer-container {
    width: 100%;
    flex-direction: column;
  }

  main {
    flex-direction: column-reverse;
  }

  .section-title {
    font-size: 1.5rem;
  }
}
